import { useEffect, useState } from 'react';
import { ScrollContainer, batch, FadeIn, StickyIn } from "react-scroll-motion";
import OurService from './component/home/service.js';
import CoreSection from './component/home/core-service.js';
import CoreSection2 from './component/home/core-service2.js';
import MottoBorder from './component/home/motto.js';
import bannerVideo from '../asset/homepage/top_bg/banner_video.mp4';
import Partner from './component/home/partner.js';
import Footer from '../component/footer.js';

/**
 * @author Nelson
 * @returns 
 */
function Home() {
    const FadeUp = batch(StickyIn(), FadeIn());
    const [windowSize, setWindowSize] = useState({});

    /**
     * Handles window resizing to dynamically adjust layout
     */
    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        handleResize(); // Ensure initial dimensions are set
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <ScrollContainer style={{ overflow: 'auto' }}>
            <div className='home-1-wrapper page-wrapper' style={{ textAlign: 'center' }}>
                <label className='w-100 white-big-title break-word ph-2' style={{ paddingTop: '2rem' }}>
                    CREATING THE FUTURE, TECHNOLOGY INCLUSION
                </label>
                <label className='w-100 white-content-text break-word' style={{ marginTop: '1rem' }}>
                    Unifying innovative solutions into one ecosystem
                </label>
            </div>
            <div className='home-2-wrapper'>
                <div className="video-banner">
                    <video
                        id="banner-video"
                        className="banner-video"
                        src={bannerVideo}
                        autoPlay
                        loop
                        controls
                        muted
                        playsInline />
                </div>
            </div>

            <div className='home-3-wrapper page-wrapper relative'>
                <MottoBorder />
            </div>
            <OurService />
            {/* Core Services */}
            <CoreSection />
            <CoreSection2 />
            <Partner />
            <Footer />
        </ScrollContainer>
    );
}

export default Home;