import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteUrl } from '../App';
import { HiBars3, HiArrowTopRightOnSquare } from "react-icons/hi2";

/**
 * @author Nelson
 * @returns 
 */
const Header = () => {
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(null);
    const [hide, setHide] = useState(false);
    const timeoutHideRef = useRef(null);

    const MENU = [
        {
            url: RouteUrl.Home,
            title: 'HOME'
        },
        {
            url: RouteUrl.AboutUs,
            title: 'ABOUT US'
        },
        {
            url: RouteUrl.News,
            title: 'NEWS'
        },
        {
            url: RouteUrl.Solution,
            title: 'SOLUTION'
        }
    ]

    /**
     * @author Nelson
     */
    const handleResize = () => {
        setShowMenu(null);
    };

    /**
     * @author Nelson
     */
    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            setShowMenu(null);
        };
    }, []);

    /**
     * @author Nelson
     */
    useEffect(() => {
        if (showMenu === true) {
            document.body.style.overflow = 'hidden';
            setHide(false);
        }
        else {
            if (timeoutHideRef.current) {
                clearTimeout(timeoutHideRef.current);
            }

            timeoutHideRef.current = setTimeout(() => {
                setHide(true);
            }, 100)
        }

        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [showMenu])

    return (
        <>
            <div className='com-header'>
                <img onClick={() => { navigate(RouteUrl.Home) }} src={require('../asset/logo.png')} height={'100%'} width={'150px'} style={{ cursor: 'pointer' }} />
                <div className='navi-bar'>
                    {
                        MENU.map((menu, index) => {
                            return (
                                <label key={index} onClick={() => { navigate(menu.url) }}>{menu.title}</label>
                            )
                        })
                    }
                </div>
                <div className='flex row justify-space-between align-item-center'>
                    <button onClick={() => { navigate(RouteUrl.ContactUs) }} className='default' style={{ borderRadius: '10px' }}>Contact Us</button>
                    <div>
                        <button className='default pv-1 ph-1 ml-1 navi-bar-mobile' onClick={() => setShowMenu(!showMenu)}>
                            <HiBars3 size={20} />
                        </button>
                        <div className={`${showMenu === null ? 'invisible' : (showMenu === true ? 'flex fade-in' : 'fade-out')} col absolute w-100 navi-bar-mobile-content`} style={{ left: 0 }}>
                            {
                                showMenu != null && showMenu === true && MENU.map((menu, index) => {
                                    return (
                                        <div className='w-100 pv-1 flex justify-space-between align-item-center' onClick={() => { navigate(menu.url); setShowMenu(false) }}>
                                            <label key={index} className='ml-2 no-select no-click'>{menu.title}</label>
                                            <HiArrowTopRightOnSquare className='mr-2 no-select no-click' style={{ color: 'white' }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`com-header-overlay fixed fit-content ${showMenu === null ? 'invisible' : (showMenu === true ? 'flex fade-in' : 'fade-out')} ${hide === true ? 'hide' : ''}`} onClick={() => setShowMenu(false)} />
        </>
    )
}

export default Header;