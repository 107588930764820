import React from 'react';

/**
 * @author Nelson
 * @returns 
 */
const Partner = () => {
    return (
        <div className='w-100 partner'>
            <div className='w-100 flex row justify-center align-item-center mb-7'>
                <label className='w-100 white-bold-title break-word text-center'>
                    What makes our <strong>TECHNOLOGY</strong> different
                </label>
            </div>
            <div className='flex row justify-center align-item-center partner-list'>
                <div className='partner-item'>
                    <div className='partner-item-inner'>
                        <div className='partner-item-front'>
                            <img src={require('../../../asset/homepage/USP_partners/end to end card.png')} />
                        </div>
                        <div className='partner-item-back'>
                            <label>Comprehensive and hassle-free vending solutions tailored to meet diverse business needs. All-in-one solution ensures a seamless vending experience.</label>
                        </div>
                    </div>
                </div>
                <div className='partner-item'>
                    <div className='partner-item-inner'>
                        <div className='partner-item-front'>
                            <img src={require('../../../asset/homepage/USP_partners/tech driven card.png')} />
                        </div>
                        <div className='partner-item-back'>
                            <label>Revolutionising vending through cutting-edge technology. Our tech-first approach ensures unmatched convenience and reliability for both businesses and consumers.</label>
                        </div>
                    </div>
                </div>
                <div className='partner-item'>
                    <div className='partner-item-inner'>
                        <div className='partner-item-front'>
                            <img src={require('../../../asset/homepage/USP_partners/marketing card.png')} />
                        </div>
                        <div className='partner-item-back'>
                            <label>Cohesive brand experience that engages customers accross multiple channels, increasing awareness and conversions. Bridge the physical and digital worlds.</label>
                        </div>
                    </div>
                </div>
                <div className='partner-item'>
                    <div className='partner-item-inner'>
                        <div className='partner-item-front'>
                            <img src={require('../../../asset/homepage/USP_partners/Vmall card.png')} />
                        </div>
                        <div className='partner-item-back'>
                            <label>VMall represents the next evolution of shopping, blending physical accessibility with digital ease. A groundbreaking concept that combines the convenience of vending with the versatility of e-Commerce.</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-100 flex row justify-center align-item-center mb-5' style={{ marginTop: '12rem' }}>
                <button className='default no-select no-click'>Our Partners</button>
            </div>
            <div className='flex row justify-center align-item-center partner-sample-list'>
                <div className="partner-sample-list-inner">
                    <img src={require('../../../asset/homepage/USP_partners/logo/island88.png')} style={{ width: '85px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/techstreet.png')} style={{ width: '180px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/elofam.png')} style={{ width: '190px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/hairstory.png')} style={{ width: '280px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/hairsaloon_ac.png')} style={{ width: '160px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/hairsaloon_ps.png')} style={{ width: '180px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/raceroom_1.png')} style={{ width: '170px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/raceroom_2.png')} style={{ width: '100px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/aneh.png')} style={{ width: '180px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/D-Haus 2.png')} style={{ width: '190px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/tuk dumpling.png')} style={{ width: '90px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/funeral.png')} style={{ width: '120px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/pawsible.png')} style={{ width: '90px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/NianNianYouYu.png')} style={{ width: '100px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/island88.png')} style={{ width: '85px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/techstreet.png')} style={{ width: '180px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/elofam.png')} style={{ width: '190px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/hairstory.png')} style={{ width: '280px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/hairsaloon_ac.png')} style={{ width: '160px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/hairsaloon_ps.png')} style={{ width: '180px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/raceroom_1.png')} style={{ width: '170px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/raceroom_2.png')} style={{ width: '100px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/aneh.png')} style={{ width: '180px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/D-Haus 2.png')} style={{ width: '190px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/tuk dumpling.png')} style={{ width: '90px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/funeral.png')} style={{ width: '120px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/pawsible.png')} style={{ width: '90px' }} />
                    <img src={require('../../../asset/homepage/USP_partners/logo/NianNianYouYu.png')} style={{ width: '100px' }} />
                </div>
            </div>
        </div>
    )
};

export default Partner;