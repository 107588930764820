import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import PriceCard from './priceCard';
import { EffectCoverflow } from 'swiper/modules';

const PlanSwiper = ({ plans, slideWidth = '75%', maxWidth = '350px' }) => (
<Swiper
    modules={[EffectCoverflow]}
    effect={'coverflow'}
    grabCursor={true}
    centeredSlides={true}
    slidesPerView={'auto'}
    initialSlide={0}
    loop={false}
    coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 2,
        slideShadows: false
    }}
>
        {plans.map((plan, index) => (
            <SwiperSlide key={index} style={{ width: slideWidth, maxWidth }}>
                <PriceCard {...plan} ribbon={plan.ribbon} />
            </SwiperSlide>
        ))}
    </Swiper>
);

export default PlanSwiper;
