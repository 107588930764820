import React from 'react';

/**
 * @author SY
 */
const SuperappDevelopment = () => {

  return (
    <h1 className='black-bold-title coming-title'>Coming Soon...</h1>
  );
};

export default SuperappDevelopment;