import React, { useState } from 'react';
import PropTypes from "prop-types";
import "../style/input.css";

/**
 * Reusable Floating Input Component
 */
const FloatingInput = ({
    label,
    type = "text",
    required = false,
    dataLabel,
    value,
    onChange,
    defaultValue,
    id,
    ...rest
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [hasValue, setHasValue] = useState(!!defaultValue);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = (e) => {
        setIsFocused(false);
        setHasValue(!!(e.target.value || value));
    };

    return (
        <div className="floating-input-container" data-label={dataLabel}>
            <input
                id={id}
                type={type}
                required={required}
                className="floating-input"
                value={value} // Controlled input
                onChange={onChange} // Pass the handler from the parent
                onFocus={handleFocus}
                onBlur={handleBlur}
                {...rest}
            />
            <label
                htmlFor={id}
                className={`floating-label ${isFocused || hasValue ? "active" : ""}`}
            >
                {label} {required ? "*" : ""}
            </label>
        </div>
    );
};

FloatingInput.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    required: PropTypes.bool,
    dataLabel: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    id: PropTypes.string,
};

export default FloatingInput;
