import React from 'react';

/**
 * @author Nelson
 * @returns 
 */
const Footer = () => {
    return (
        <div className='com-footer'>
            <div className='com-footer-content'>
                <div className='flex justify-center align-item-center col text-center' style={{ paddingTop: '5%' }}>
                    <label className='title-1 black-bold-title break-word ph-2'>Be part of the</label>
                    <label className='title-2 black-big-title break-word ph-2'>FUTURE TECHNOLOGY</label>
                    <label className='title-3 black-content-text break-word ph-2'>Creating and breaking boundaries with confidence</label>
                    <button className='gradient mt-3'>JOIN US</button>
                </div>
            </div>
            <div className='com-footer-bottom-content absolute'>
                <div className='flex justify-space-between align-item-center row w-100 h-auto pt-8'>
                    <div className='com-footer-bottom-content-item flex col justify-space-between align-item-start text-left p-3' style={{ maxWidth: '40%' }}>
                        <div className='com-footer-bottom-content-item-header'>
                            <img src={require('../asset/logo.png')} className='com-footer-logo' />
                        </div>
                        <label className='mt-1 break-word white-space-normal'>At Venturestar, we integrate technology to create a connected, convenient, and efficient world. We specialise in Vending & Marketing Solutions, Software & SuperApp Development, building an ecosystem that helps businesses thrive and enhances lives.</label>
                        <div className='w-100 flex row justify-start align-item-center mt-2' style={{ gap: '2%' }}>
                            <img className='com-footer-social-media-icon' src={require('../asset/footer/LinkedIn.png')} />
                            <img className='com-footer-social-media-icon' src={require('../asset/footer/Facebook.png')} />
                            <img className='com-footer-social-media-icon' src={require('../asset/footer/Insta.png')} />
                            <img className='com-footer-social-media-icon' src={require('../asset/footer/XHS.png')} />
                            <img className='com-footer-social-media-icon' src={require('../asset/footer/tiktok.png')} />
                        </div>
                    </div>
                    <div className='com-footer-bottom-content-item flex col justify-space-between align-item-start text-left p-3' style={{ maxWidth: '25%' }}>
                        <div className='com-footer-bottom-content-item-header'>
                            <label>Company</label>
                        </div>
                        <label className='mt-1 break-word white-space-normal'>1-L2-3A, Metro Avenue, Lintang Hajjah Rehmah, 11600, Penang</label>
                        <label className='break-word white-space-normal' onClick={() => window.location.href = 'mailto:admin@venturestar.com.my'}>admin@venturestar.com.my</label>
                        <label>+604 280 5404</label>
                    </div>
                    <div className='com-footer-bottom-content-item flex col justify-space-between align-item-start text-left p-3' style={{ maxWidth: '25%' }}>
                        <div className='com-footer-bottom-content-item-header'>
                            <label>Navigation</label>
                        </div>
                        <label className='mt-1'>Home</label>
                        <label className='break-word white-space-normal'>About Us</label>
                        <label>News</label>
                        <label>Solutions</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;