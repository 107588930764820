import React, { useEffect, useRef } from 'react';

/**
 * @author Nelson
 * @returns 
 */
const SolutionHeader = ({ onSelect, selected }) => {
    const elementRef = useRef({});

    useEffect(() => {
        elementRef.current[selected].scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
    }, [selected])

    return (
        <div className='solution-header text-center'>
            <div className='w-100 flex col absolute pt-10' style={{ margin: '0 auto', maxWidth: '90%', top: '2rem' }}>
                <label className='w-100 white-big-title'>OUR SOLUTIONS</label>
                <label className='w-100 white-big-text pt-1'>"Empowering Business with Smart Vending, Targeted Marketing, and Seamless Digital Solutions"</label>
            </div>
            <div className='w-100 flex justify-center align-item-center solution-header-content'>
                <div ref={(reference) => elementRef.current[1] = reference} className='solution-header-item' onClick={() => onSelect && onSelect(1)}>
                    <img src={selected !== 1 ? require('../../../asset/solutions/Vending Machine/top/Vending Machine_hide.png') : require('../../../asset/solutions/Vending Machine/top/Vending Machine.png')} />
                    <div className={`solution-header-item-label-wrapper${selected === 1 ? '' : '-hide'}`}>
                        <label className='black-content-text'>Vending Machine (Flyer)</label>
                    </div>
                </div>
                <div ref={(reference) => elementRef.current[2] = reference} className='solution-header-item' onClick={() => onSelect && onSelect(2)}>
                    <img src={selected !== 2 ? require('../../../asset/solutions/Vending Machine/top/Vending & Marketing_hide.png') : require('../../../asset/solutions/Vending Machine/top/Vending & Marketing.png')} />
                    <div className={`solution-header-item-label-wrapper${selected === 2 ? '' : '-hide'}`}>
                        <label className='black-content-text'>Vending & Marketing Solution</label>
                    </div>
                </div>
                <div ref={(reference) => elementRef.current[3] = reference} className='solution-header-item' onClick={() => onSelect && onSelect(3)}>
                    <img src={selected !== 3 ? require('../../../asset/solutions/Vending Machine/top/software_hide.png') : require('../../../asset/solutions/Vending Machine/top/Software.png')} />
                    <div className={`solution-header-item-label-wrapper${selected === 3 ? '' : '-hide'}`}>
                        <label className='black-content-text'>Software Development</label>
                    </div>
                </div>
                <div ref={(reference) => elementRef.current[4] = reference} className='solution-header-item' onClick={() => onSelect && onSelect(4)}>
                    <img src={selected !== 4 ? require('../../../asset/solutions/Vending Machine/top/superapp_hide.png') : require('../../../asset/solutions/Vending Machine/top/Superapp.png')} />
                    <div className={`solution-header-item-label-wrapper${selected === 4 ? '' : '-hide'}`}>
                        <label className='black-content-text'>SuperApp Development</label>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SolutionHeader;