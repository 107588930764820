import React, { useState } from 'react';
import { ScrollContainer } from 'react-scroll-motion';
import SolutionHeader from './component/solution/header';
import VendingMachine from './component/solution/vendingMachine';
import VendingMarketing from './component/solution/vending_marketing/index';
import SoftwareDevelopment from './component/solution/softwareDevelopment';
import SuperappDevelopment from './component/solution/superappDevelopment';

/**
 * @author Nelson
 */
const Solution = () => {
    const [selected, setSelected] = useState(1);

    const components = [
        <VendingMachine />,
        <VendingMarketing />,
        <SoftwareDevelopment />,
        <SuperappDevelopment />
    ];

    const footerContent = [
        {
            image: require('../asset/solutions/Vending Machine/bg/sticky.png'),
            text: "👋  Ready to start your vending business? Talk to us today! — +604 280 540",
        },
        {
            image: require('../asset/solutions/Vending Machine/bg/CTA.png'),
            text: "🚀  Tailored solutions for Your Vending Needs, boost your sales today — +604 280 5404",
        },
        {
            image: require('../asset/solutions/Vending Machine/bg/sticky-software.png'),
            text: "🌐  Innovate Together, Cater to Every Need.",
        },
        {
            image: require('../asset/solutions/Vending Machine/bg/sticky-app.png'),
            text: "🤩  One App for All Your Needs, Stay Tuned for the launch."
        }
    ];

    return (
        <ScrollContainer style={{ overflow: 'auto', backgroundColor: 'white', minHeight: '100dvh' }}>
            <SolutionHeader selected={selected} onSelect={setSelected} />

            <div className="solution-main-content">
                {components[selected - 1]}
            </div>

            <div className='solution-footer '>
                <div className='solution-footer-info-wrapper'>

                    <div className='footer-image-container'>
                        <img
                            src={footerContent[selected - 1].image}
                            alt="Footer illustration"
                            className='footer-image'
                        />
                    </div>
                    <div className='footer-text-container footer-scroll'>
                        <span className='footer-text'>{footerContent[selected - 1].text}</span>
                    </div>
                </div>
            </div>
        </ScrollContainer>
    )
}

export default Solution;