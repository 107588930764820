import React, { useState, useEffect } from "react";

const MottoBorder = () => {
  const [leftText, setLeftText] = useState("Enhancing Humanity");
  const [nextText, setNextText] = useState("Empowering Diversity");
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const texts = [
      "Enhancing Humanity",
      "Empowering Diversity",
      "Transforming Complexity",
    ];
    let index = 0;

    const interval = setInterval(() => {
      // Start the slide-up animation for the next text
      setIsAnimating(true);

      // After 1 second, change to the next text
      setTimeout(() => {
        index = (index + 1) % texts.length; // Update the index
        setLeftText(texts[index]); // Update the current text
        setNextText(texts[(index + 1) % texts.length]); // Set the next text
        setIsAnimating(false); // End the animation
      }, 1000); // Wait for the animation duration before switching

    }, 2000); // Trigger every 2 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <div className="motto-border">
      <div className="line text-left w-100">
        <label className={`${isAnimating ? "slide-up white-big-text" : "white-big-text"} break-all line1`}>{leftText}</label>
      </div>
      <div className="line text-right w-100">
        <label className="white-bold-title line1 break-all">THROUGH TECHNOLOGY</label>
      </div>
    </div>
  );
};

export default MottoBorder;