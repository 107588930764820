import React from 'react';

/**
 * @author SY
 */
const VendingMachine = () => {

  const tableData = [
    { feature: "Dimension", value: "H: 1940mm W: 1269mm D: 795mm" },
    { feature: "Screen", value: "21.5\" Touch Screen" },
    { feature: "Weight", value: "300kgs" },
    { feature: "Type", value: "Spiral Tray" },
    { feature: "Power", value: "Refrigerated 442W Normal 44W" },
    { feature: "Temperature", value: "4°C - 25°C" },
    { feature: "Payment Method", value: "Bill, Cashless payment" },
  ];

  return (
    <>
      <h1 className='black-bold-title coming-title'>Coming Soon...</h1>
    </>
  );
};

export default VendingMachine;