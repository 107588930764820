import React from 'react';

/**
 * @author SY
 * @returns 
 */
const AdditionalService = () => {
  const services = [
    {
      bullet: '•',
      price: 'RM199',
      note: '(with ready template)',
      separator: '|',
      name: 'Top Bar Design',
      spec: '(120cm x 40cm)'
    },
    {
      bullet: '•',
      price: 'RM299',
      note: '(with ready template)',
      separator: '|',
      name: 'Digital Screen Design'
    },
    {
      bullet: '•',
      price: 'RM299',
      note: '(Ori: RM250)',
      separator: '|',
      name: 'Sticker Dismantle'
    },
    {
      bullet: '•',
      price: 'RM699',
      note: '(Ori: RM600)',
      separator: '|',
      name: 'Sticker Printing'
    },
    {
      bullet: '•',
      price: 'RM299',
      note: '(Ori: RM200)',
      separator: '|',
      name: 'Sticker Installation'
    },
    {
      bullet: '•',
      price: 'RM899',
      note: '(Ori: RM800)',
      separator: '|',
      name: 'Printing + Installation'
    }
  ];

  return (
    <div className="additional-services">
      <button className="button ">Additional Services</button>
      <div className="services-list">
        {services.map((service, index) => (
          <div key={index} className="service-item">
            <div className="service-main">
              <span className="bullet white-content-text">{service.bullet}</span>
              <span className="price white-content-text">{service.price}</span>
              <span className="note white-content-text">{service.note}</span>
            </div>
            <div className="service-details">
              <span className="separator white-content-text">{service.separator}</span>
              <span className="name white-content-text">{service.name}</span>
              {service.spec && <span className="spec white-content-text">{service.spec}</span>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default AdditionalService;