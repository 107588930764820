import React from 'react';

/**
 * @author Nelson
 */
const News = () => {
    return (
        <>News~</>
    )
}

export default News;