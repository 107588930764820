import React, { useState } from 'react';
import PriceCard from './priceCard';
import AdditionalService from './additionalService';
import PlanSwiper from './planSwiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';
import 'swiper/css/autoplay';

/**
 * @author SY
 */
const VendingMarketing = () => {
    const [activeTab,
        setActiveTab] = useState('monthly');
    const salesPlans = [
        {
            planType: 'Monthly',
            price: 'RM19.90',
            theme: 'blue',
            isDark: false,
            features: [
                {
                    name: 'Merchant <b>Google Form Consultation</b> (1)',
                    available: true
                }, {
                    name: 'A Proper <b>Solution PPT</b>',
                    available: true
                }, {
                    name: 'Exclusive Offline Listing on <b>VMall</b>',
                    available: true
                }, {
                    name: '<b>Promotion</b> on our <b>Social Media Page (Facebook)</b>',
                    available: true
                }, {
                    name: '<b>Digital Screen Design</b> (1 Copy)',
                    available: false
                }, {
                    name: '<b>Advertisement</b> Space',
                    available: false
                }, {
                    name: '<b>Sticker Designing</b> Service',
                    available: false
                }, {
                    name: '<b>Live-Support</b> Service',
                    available: false
                }
            ],
            ribbon: {
                class: null,
                text: null
            }
        }, {
            planType: 'Quarterly',
            price: 'RM54.90',
            theme: 'blue',
            isDark: false,
            features: [
                {
                    name: 'Merchant <b>Google Form Consultation</b> (1)',
                    available: true
                }, {
                    name: 'A Proper <b>Solution PPT</b>',
                    available: true
                }, {
                    name: 'Exclusive Offline Listing on <b>VMall</b>',
                    available: true
                }, {
                    name: '<b>Promotion</b> on our <b>Social Media Page (Facebook)</b>',
                    available: true
                }, {
                    name: '<b>Digital Screen Design</b> (1 Copy)',
                    available: true
                }, {
                    name: '<b>Advertisement</b> Space',
                    available: true
                }, {
                    name: '<b>Sticker Designing</b> Service',
                    available: false
                }, {
                    name: '<b>Live-Support</b> Service',
                    available: false
                }
            ],
            ribbon: {
                class: 'save',
                text: 'Save More'
            }
        }, {
            planType: 'Yearly',
            price: 'RM229.90',
            theme: 'blue',
            isDark: true,
            features: [
                {
                    name: 'Merchant <b>Google Form Consultation</b> (1)',
                    available: true
                }, {
                    name: 'A Proper <b>Solution PPT</b>',
                    available: true
                }, {
                    name: 'Exclusive Offline Listing on <b>VMall</b>',
                    available: true
                }, {
                    name: '<b>Promotion</b> on our <b>Social Media Page (Facebook)</b>',
                    available: true
                }, {
                    name: '<b>Digital Screen Design</b> (1 Copy)',
                    available: true
                }, {
                    name: '<b>Advertisement</b> Space',
                    available: true
                }, {
                    name: '<b>Sticker Designing</b> Service',
                    available: true
                }, {
                    name: '<b>Live-Support</b> Service',
                    available: true
                }
            ],
            ribbon: {
                class: 'recommended',
                text: 'Recommended'
            }
        }
    ];

    const brandingPlans = [
        {
            planType: 'Monthly',
            price: 'RM39.90',
            theme: 'yellow',
            isDark: false,
            features: [
                {
                    name: '<b>Analysis Report:</b> (Location Analysis// Product Analysis// Positioning// Sa' +
                        'les Review)',
                    available: true
                }, {
                    name: 'Exclusive Offline Listing on <b>VMall</b>',
                    available: true
                }, {
                    name: '<b>Promotion</b> on our <b>Social Media Page (Facebook)',
                    available: true
                }, {
                    name: '<b>Digital Screen Design</b> (1 Copy)',
                    available: false
                }, {
                    name: '<b>Advertisement</b> Space',
                    available: false
                }, {
                    name: '<b>Sticker Designing</b> Service',
                    available: false
                }, {
                    name: '<b>Live-Support</b> Service',
                    available: false
                }
            ],
            ribbon: {
                class: null,
                text: null
            }
        }, {
            planType: 'Quarterly',
            price: 'RM104.90',
            theme: 'yellow',
            isDark: false,
            features: [
                {
                    name: '<b>Analysis Report:</b> (Location Analysis// Product Analysis// Positioning// Sa' +
                        'les Review)',
                    available: true
                }, {
                    name: 'Exclusive Offline Listing on <b>VMall</b>',
                    available: true
                }, {
                    name: '<b>Promotion</b> on our <b>Social Media Page (Facebook)</b>',
                    available: true
                }, {
                    name: '<b>Digital Screen Design</b> (1 Copy)',
                    available: true
                }, {
                    name: '<b>Advertisement</b> Space',
                    available: true
                }, {
                    name: '<b>Sticker Designing</b> Service',
                    available: false
                }, {
                    name: '<b>Live-Support</b> Service',
                    available: false
                }
            ],
            ribbon: {
                class: 'save',
                text: 'Save More'
            }
        }, {
            planType: 'Yearly',
            price: 'RM469.90',
            theme: 'yellow',
            isDark: true,
            features: [
                {
                    name: '<b>Analysis Report:</b> (Location Analysis// Product Analysis// Positioning// Sa' +
                        'les Review)',
                    available: true
                }, {
                    name: 'Exclusive Offline Listing on <b>VMall</b>',
                    available: true
                }, {
                    name: '<b>Promotion</b> on our <b>Social Media Page (Facebook)</b>',
                    available: true
                }, {
                    name: '<b>Digital Screen Design</b> (1 Copy)',
                    available: true
                }, {
                    name: '<b>Advertisement</b> Space',
                    available: true
                }, {
                    name: '<b>Sticker Designing</b> Service',
                    available: true
                }, {
                    name: '<b>Live-Support</b> Service',
                    available: true
                }
            ],
            ribbon: {
                class: 'recommended',
                text: 'Recommended'
            }
        }
    ];
    const monthlyServices = [
        {
            planType: 'Social Media Management',
            price: 'RM799',
            subPrice: '/month',
            theme: 'default',
            isDark: false,
            features: [
                {
                    name: '(12) Contents',
                    available: true
                }, {
                    name: 'Monthly Content Calendar',
                    available: true
                }, {
                    name: 'Content Designing',
                    available: true
                }, {
                    name: 'Caption Writing',
                    available: true
                }, {
                    name: 'Social Media Posting',
                    available: true
                },
                {
                    name: 'Advertisement Space (LIMITED SPACE only)',
                    available: false
                }
            ],
            ribbon: {
                class: null,
                text: null
            }
        },
        {
            planType: 'Reel Marketing',
            price: 'RM999',
            subPrice: '/month',
            theme: 'default',
            isDark: false,
            features: [
                {
                    name: '(3) short videos (max 45 secs each)',
                    available: true
                }, {
                    name: 'Storyboard planning',
                    available: true
                }, {
                    name: 'Video shooting service',
                    available: true
                }, {
                    name: 'Video editing service',
                    available: true
                }, {
                    name: 'Advertisement Space (LIMITED SPACE only)',
                    available: false
                }
            ],
            ribbon: {
                class: null,
                text: null
            }
        },
        {
            planType: 'META Advertisement',
            price: 'RM199',
            subPrice: '/month',
            theme: 'default',
            isDark: false,
            features: [
                {
                    name: ' (1) Ads setting',
                    available: true
                }, {
                    name: ' META ads setting including Detailed Targeting, CA & LA.',
                    available: true
                }, {
                    name: 'Advertisement Space(LIMITED SPACE only)',
                    available: false
                }
            ],
            ribbon: {
                class: null,
                text: null
            }
        }
    ];
    const quarterlyServices = [
        {
            planType: 'Social Media Management',
            price: 'RM783',
            subPrice: '/month',
            theme: 'yellow',
            isDark: true,
            features: [
                {
                    name: '(12) Contents',
                    available: true
                }, {
                    name: 'Monthly Content Calendar',
                    available: true
                }, {
                    name: 'Content Designing',
                    available: true
                }, {
                    name: 'Caption Writing',
                    available: true
                }, {
                    name: 'Social Media Posting',
                    available: true
                },
                {
                    name: 'Advertisement Space (LIMITED SPACE only)',
                    available: true
                }
            ],
            ribbon: {
                class: 'recommended',
                text: 'Save RM48'
            }
        },
        {
            planType: 'Reel Marketing',
            price: 'RM983',
            subPrice: '/month',
            theme: 'yellow',
            isDark: true,
            features: [
                {
                    name: '(3) short videos (max 45 secs each)',
                    available: true
                }, {
                    name: 'Storyboard planning',
                    available: true
                }, {
                    name: 'Video shooting service',
                    available: true
                }, {
                    name: 'Video editing service',
                    available: true
                }, {
                    name: 'Advertisement Space (LIMITED SPACE only)',
                    available: true
                }
            ],
            ribbon: {
                class: 'recommended',
                text: 'Save RM48'
            }
        },
        {
            planType: 'META Advertisement',
            price: 'RM199',
            subPrice: '/month',
            theme: 'yellow',
            isDark: true,
            features: [
                {
                    name: ' (1) Ads setting',
                    available: true
                }, {
                    name: ' META ads setting including Detailed Targeting, CA & LA.',
                    available: true
                }, {
                    name: 'Advertisement Space(LIMITED SPACE only)',
                    available: true
                }
            ],
            ribbon: {
                class: 'recommended',
                text: 'Save RM48'
            }
        }
    ];

    const currentServices = activeTab === 'monthly' ? monthlyServices : quarterlyServices;

    return (
        <div className="vending-marketing">
            <div className="intro-section">
                <img
                    src={require('../../../../asset/solutions/vending_marketing/intro/VMallRadialDiagram.png')}
                    alt="VMall Radial Diagram"
                    className="intro-diagram" />
                <div className="intro-text">
                    <h2 className='black-title'>Vending & Marketing Solution</h2>
                    <p className='black-content-text'>Focuses on empowering businesses to boost sales and enhance brand visibility
                        through innovative and customized services. And this solution specializes in
                        identifying issues and analyzing performance to help merchant business thrive.</p>
                </div>
            </div>
            <div className="package-section">
                <button className="plan-button">Subscription Plan</button>
                <div className="plan-text">
                    <h2 className='white-title'>Vending Solution</h2>
                    <p className='white-content-text'>Focuses on empowering businesses to boost sales and enhance brand visibility
                        through innovative and customized services. And this solution specializes in
                        identifying issues and analyzing performance to help merchant business thrive.</p>
                </div>
                <div className="mobile-view">
                    <button className="package-button sales-button">
                        <span>Sales Boosting Package</span>
                        <img src={require('../../../../asset/solutions/vending_marketing/pricing/sales/boosting_icon.png')} alt="" className="button-icon" />
                    </button>
                    <PlanSwiper plans={salesPlans} />
                    <button className="package-button branding-button">
                        <span>Branding Package</span>
                        <img src={require('../../../../asset/solutions/vending_marketing/pricing/branding/branding_icon.png')} alt="" className="button-icon" />
                    </button>
                    <PlanSwiper plans={brandingPlans} />
                </div>

                <div className="desktop-view">
                    <div className="sales-section">
                        <div className="sales-cards-container">
                            {salesPlans.map((plan, index) => (<PriceCard
                                key={index}
                                {...plan}
                                ribbon={plan.ribbon} />))}
                        </div>
                    </div>
                    <div className="branding-section">
                        <div className="branding-cards-container">
                            {brandingPlans.map((plan, index) => (<PriceCard
                                key={index}
                                {...plan}
                                ribbon={plan.ribbon} />))}
                        </div>
                    </div>
                </div>
                <AdditionalService />
                <img
                    src={require('../../../../asset/solutions/vending_marketing/pricing/blend.png')}
                    alt="blend" className="blend-image" />

                <div className="plan-text">
                    <h2 className='white-title'>Marketing Solution</h2>
                    <p className='white-content-text'>Comprehensive service designed to amplify merchant’s brand online presence,
                        engage your audience, and drive results. Help businesses stand out in the
                        digital landscape no matter its offline or online businesses.</p>
                </div>

                <div className="switch-button">
                    <button
                        className={`switch-option ${activeTab === 'monthly' ? 'active' : ''}`}
                        onClick={() => setActiveTab('monthly')}
                    >
                        Monthly
                    </button>
                    <button
                        className={`switch-option ${activeTab === 'quarterly' ? 'active' : ''}`}
                        onClick={() => setActiveTab('quarterly')}
                    >
                        Quarterly
                    </button>
                </div>
                <div className="mobile-view">
                    <div className="marketing-cards-container">
                        <PlanSwiper plans={currentServices} />
                    </div>
                </div>

                <div className="desktop-view">
                    <div className="marketing-cards-container">
                        {currentServices.map((plan, index) => (<PriceCard
                            key={index}
                            {...plan}
                            ribbon={plan.ribbon} />))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VendingMarketing;