import React from 'react';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';

const PriceCard = ({
  planType,
  price,
  features,
  ribbon,
  subPrice,
  theme = 'default',
  isDark = false,
  isMetaCard = false
}) => {

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  return (
    <div className={`
      pricing-card 
      ${planType.toLowerCase().replace(' ', '-')} 
      theme-${theme} 
      ${isDark ? 'dark-bg' : ''} 
      ${isMetaCard ? 'meta-card' : ''}
    `}>
      <div className="header">
        <div className="plan-type">{planType}</div>
        <div className="price-container">
          <span className="price" >{price}</span>
          {subPrice && <span className="sub-price" style={{ color: isDark ? 'white' : 'var(--primary-dark)' }}>{subPrice}</span>}
        </div>
      </div>
      <div className={`divider ${isDark ? 'divider-dark' : 'divider-light'}`} />

      <ul className="features">
        {features.map((feature, index) => (
          <li
            key={index}
            className={"feature-item"}
          >
            <span className={`icon ${feature.available ? 'available' : 'unavailable'}`}>
              {feature.available ? <BsCheckCircleFill size={18} /> : <BsXCircleFill size={18} />}
            </span>
            <span
              className="feature-text"
              dangerouslySetInnerHTML={createMarkup(feature.name)}
            />
          </li>
        ))}
      </ul>

      <div className="card-footer">
        <div className="terms">*T&C Apply</div>
        <button className="quote-btn">Get A Quote</button>
      </div>

      {ribbon && (
        <div className={`ribbon ${ribbon.class}`}>
          {ribbon.text}
        </div>
      )}
    </div>
  );
};

export default PriceCard;