import React, { useState } from "react";
import FloatingInput from "../component/input";
import locat from "../asset/contactUs/location.svg";
import phone from "../asset/contactUs/phone_icon.svg";
import email from "../asset/contactUs/email.svg";

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: "",
        company: "",
        email: "",
        phone: "",
    });
    const [textareaValue, setTextareaValue] = useState("");
    const [dropdownValue, setDropdownValue] = useState("General Enquiry");

    const handleInputChange = (field, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const mailtoLink = `mailto:admin@venturestar.com.my?subject=${encodeURIComponent(
            dropdownValue
        )}&body=${encodeURIComponent(
            `Name: ${formData.name}\nCompany: ${formData.company}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nMessage: ${textareaValue}`
        )}`;
        window.location.href = mailtoLink;
    };

    return (
        <div className="contact-us-container">
            <div className="both-panel flex row justify-space-evenly align-item-center">
                <div className="left-panel">
                    <div className="contact-title-container">
                        <label className="white-big-title title">
                            Take the <strong>FIRST</strong> step
                        </label>
                    </div>

                    <p className="description white-content-text break-word">
                        Reach out and drive your business to the next level!
                    </p>
                    <ul className="contact-info pt-5">
                        <li>
                            <span className="icon">
                                <img src={locat} alt="Location Icon" />
                            </span>
                            1-L2-3A, Metro Avenue, Lintang Hajjah Rehmah, 11060, Penang
                        </li>
                        <li>
                            <span className="icon">
                                <img src={phone} alt="Phone Icon" />
                            </span>
                            +604 280 5404
                        </li>
                        <li>
                            <span className="icon">
                                <img src={email} alt="Email Icon" />
                            </span>
                            admin@venturestar.com.my
                        </li>
                    </ul>
                </div>

                <div className="form-panel blur-background relative">
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <div className="input-1 flex row">
                            <FloatingInput
                                label="Name"
                                required
                                value={formData.name}
                                onChange={(e) => handleInputChange("name", e.target.value)}
                            />
                            <FloatingInput
                                label="Company"
                                value={formData.company}
                                onChange={(e) => handleInputChange("company", e.target.value)}
                            />
                        </div>
                        <div className="input-2 flex row">
                            <FloatingInput
                                label="Email"
                                type="email"
                                required
                                value={formData.email}
                                onChange={(e) => handleInputChange("email", e.target.value)}
                            />
                            <FloatingInput
                                label="Phone"
                                type="tel"
                                required
                                value={formData.phone}
                                onChange={(e) => handleInputChange("phone", e.target.value)}
                            />
                        </div>
                        <div className="service-input-container">
                            <div className="dropdown-row">
                                <label className="dropdown-label">Service Type</label>
                                <select
                                    className="service-dropdown"
                                    value={dropdownValue}
                                    onChange={(e) => setDropdownValue(e.target.value)}
                                >
                                    <option value="General Enquiry">General Enquiry</option>
                                    <option value="Partnership Opportunity">
                                        Partnership Opportunity
                                    </option>
                                    <option value="Technical Support">Technical Support</option>
                                    <option value="Solution - Vending Machine Enquiry">
                                        Solution - Vending Machine Enquiry
                                    </option>
                                    <option value="Solution - Marketing Solution Enquiry">
                                        Solution - Marketing Solution Enquiry
                                    </option>
                                    <option value="Solution - Software Development Enquiry">
                                        Solution - Software Development Enquiry
                                    </option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>

                            <textarea
                                className="service-textarea"
                                value={textareaValue}
                                onChange={(e) => setTextareaValue(e.target.value)}
                                placeholder="I would like to learn more about your services and how they can benefit my business. Please provide additional information or arrange a call to discuss further."
                            ></textarea>
                        </div>
                        <div className="button-container flex justify-end mt-5">
                            <button type="submit" className="submit-button">
                                Submit
                                <span className="arrow">→</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
