import React, { useState, useEffect } from 'react';
import arrowWhite from '../asset/aboutpage/arrow_white.svg';
import knowmoreArrow from '../asset/aboutpage/knowmore_arrow.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import HTMLFlipBook from 'react-pageflip';
import { RouteUrl } from '../App';
import { ScrollContainer } from 'react-scroll-motion';
/**
 * @author Nelson
 */
const AboutUs = () => {
    const [activeSection, setActiveSection] = useState(0);
    const _location = useLocation();
    const [bookSize, setBookSize] = useState({ width: 400, height: 600 });
    const [isResizing, setIsResizing] = useState(false);
    const [singlePage, setSinglePage] = useState(false);
    const _navigate = useNavigate();

    /**
     * @author SY
     */
    useEffect(() => {
        window.scrollTo(0, 0);
        updateDimensions();

        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [_location]);

    /**
    * @author SY
    */
    const updateDimensions = () => {
        setIsResizing(true);
        const screenWidth = Math.round(window.innerWidth);

        setSinglePage(screenWidth <= 800);

        const dimensionMap = {
            760: { widthRatio: 0.6, heightRatio: 1.35 },
            800: { widthRatio: 0.53, heightRatio: 1.35 },
            1000: { widthRatio: 0.3, heightRatio: 1.4 },
            1200: { widthRatio: 0.31, heightRatio: 1.5 },
            1400: { widthRatio: 0.3, heightRatio: 1.5 },
            1600: { widthRatio: 0.3, heightRatio: 1.5 },
            default: { widthRatio: 0.27, heightRatio: 1.5 }
        };
        const breakpoint = Object.keys(dimensionMap)
            .find(bp => screenWidth <= Number(bp)) || 'default';

        const { widthRatio, heightRatio } = dimensionMap[breakpoint];
        const width = Math.round(screenWidth * widthRatio);
        const height = Math.round(width * heightRatio);

        setBookSize({ width, height });
        setTimeout(() => setIsResizing(false), 100);
    };

    /**
    * @author SY
    */
    const solutions = [
        {
            title: "Vending & Marketing Solutions",
            description: "Revolutionizing the vending industry with end-to-end smart vending operations, integrated ads, additional paybill & reload services & payment systems, and targeted marketing strategies.",
            benefits: "Benefits: Our solutions help vending businesses increase operational efficiency, boost sales and customer engagement.",
            image: require('../asset/aboutpage/vending.png'),
            navigate: RouteUrl.Solution
        },
        {
            title: "Software Development",
            description: "Our flexible software solutions cater to unique business needs, working independently or as part of a larger system - standalone or API-driven. ",
            benefits: "Benefits: We tailor solutions for a variety of industries, enhancing operational efficiency and streamlining processes.",
            image: require('../asset/aboutpage/software.png'),
            navigate: RouteUrl.Solution
        },
        {
            title: "Superapp Development",
            description: "Our SuperApp connects innovative solutions into one unified platform, creating a full ecosystem that makes resources accessible, empowers businesses, and enhances lives worldwide.",
            image: require('../asset/aboutpage/superapp.png'),
            navigate: RouteUrl.Solution
        }
    ];

    /**
    * @author SY
    */
    const pages = [
        { src: require('../asset/aboutpage/vee_story/page_1.png') },
        { src: require('../asset/aboutpage/vee_story/page_2.png') },
        { src: require('../asset/aboutpage/vee_story/page_3.png') },
        { src: require('../asset/aboutpage/vee_story/page_4.png') },
        { src: require('../asset/aboutpage/vee_story/page_5.png') },
        { src: require('../asset/aboutpage/vee_story/page_6.png') },
        { src: require('../asset/aboutpage/vee_story/page_7.png') },
        { src: require('../asset/aboutpage/vee_story/page_8.png') },


    ];

    return (
        <ScrollContainer className="about-page" style={{ overflow: 'auto' }}>
            <div className="top-section">
                <button className="about-button">About Us</button>
                <p className="intro-text white-content-text">
                    At Venturestar, we integrate technology into daily life to create a more connected,
                    convenient, and efficient world. Our expertise lies in
                </p>

                <div className="icons-section">
                    <div className="icons-container">
                        {solutions.map((solution) => (
                            <div key={solution.title} className="icon-item">
                                <img src={solution.image} alt={solution.title} />
                                <span className="icon-label">{solution.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className={`bottom-section bg-section-${activeSection}`}>
                <div className="buttons-section">
                    {solutions.map((solution, index) => (
                        <div key={solution.title} >
                            {activeSection === index ? (
                                <div className="content-box">
                                    <h2 className='white-title'>{solution.title}</h2>
                                    <p className='white-content-text'>{solution.description}</p>
                                    {solution.benefits && (
                                        <p className='white-content-text'>{solution.benefits}</p>
                                    )}
                                </div>
                            ) : (
                                <button
                                    className="solution-button"
                                    onClick={() => setActiveSection(index)}
                                >
                                    <h2>{solution.title}</h2>
                                    <img src={arrowWhite} alt="arrow" className="arrow" />
                                </button>
                            )}
                        </div>
                    ))}
                </div>
                <div className="know-more" onClick={() => _navigate(solutions[activeSection].navigate)}>
                    <img src={knowmoreArrow} alt="know more" />
                    <span>know more</span>
                </div>
            </div>

            <div className="org-chart-overview">
                <div className="org-chart-header">
                    <div className="org-chart-title">Organizational Chart</div>
                </div>
                <img src={require('../asset/aboutpage/organizational_chart/all_department.png')} alt="Organization Chart" />
            </div>

            <div className="flipbook-container" >
                {!isResizing && (
                    <HTMLFlipBook
                        width={bookSize.width}
                        height={bookSize.height}
                        showCover={false}
                        drawShadow={false}
                        flippingTime={800}
                        size="fixed"
                        maxShadowOpacity={0}
                        mobileScrollSupport={false}
                    >
                        {(singlePage ? pages.slice(1) : pages).map((page, index) => (
                            <div key={index} className="flipbook-page">
                                <img
                                    src={page.src}
                                    alt={`Page ${index + (singlePage ? 1 : 0)}`}
                                />
                            </div>
                        ))}
                    </HTMLFlipBook>
                )}
            </div>
        </ScrollContainer>
    );
};

export default AboutUs;