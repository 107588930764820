import React, { useState, useEffect, useRef } from 'react';
import vendingGraphic from '../../../asset/homepage/vending.png';
import core1Graphic from '../../../asset/homepage/01_graphic.png';
import core2Graphic from '../../../asset/homepage/02_graphic.png';
import core3Graphic from '../../../asset/homepage/03_graphic.png';

/**
 * @author Angel
 * @returns 
 */

function CoreSection() {
    const [activeSection, setActiveSection] = useState(1);

    const sectionsRef = useRef([]);

    const handleScroll = () => {
        sectionsRef.current.forEach((section, index) => {
            const rect = section.getBoundingClientRect();
            if (rect.top >= 0 && rect.top < window.innerHeight / 1.5) {
                setActiveSection(index + 1);
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className='core-1-wrapper page-wrapper flex col' style={{ paddingTop: '5rem' }}>
            <div className='w-100 flex row justify-center align-item-center mb-8'>
                <button className='border-black no-select no-click' style={{ fontSize: '20px' }}>Core Services</button>
            </div>
            <div className='padding-wrapper flex-wrapper'>
                {/* Left Content */}
                <div className='core-left-content'>
                    <div className='core-content-scroll'>
                        <label className={`space-div black-title break-word white-space-normal pt-5 ${activeSection === 1 ? 'focused' : ''}`}>
                            VENDING & MARKETING<br /> SOLUTIONS
                        </label>
                        <br />
                        <br />
                        <div
                            ref={(el) => (sectionsRef.current[0] = el)}
                            className={`core-1-content ${activeSection === 1 ? 'black-content-text' : 'gray-content-text'
                                }`}
                        >
                            Designed to <strong>empower merchants</strong> by combining automated vending services
                            with impactful marketing strategies, enabling merchants to<strong> boost sales,
                                enhance brand presence</strong>, and <strong>engage in innovative ways.</strong>
                        </div>
                        <br />
                        <div
                            ref={(el) => (sectionsRef.current[1] = el)}
                            className={`core-2-content ${activeSection === 2 ? 'black-content-text' : 'gray-content-text'
                                }`}
                        >
                            The solutions include a comprehensive suite of <strong>merchant solutions</strong>, tailored to
                            help businesses thrive in the <strong>modern retail landscape. </strong>
                        </div>
                        <br />
                        <div
                            ref={(el) => (sectionsRef.current[2] = el)}
                            className={`core-3-content ${activeSection === 3 ? 'black-content-text' : 'gray-content-text'
                                }`}
                        >
                            <strong>Example:</strong> Sales Boosting Package // Branding Package // Social Media
                            Management // Reel Marketing
                        </div>
                    </div>
                </div>

                {/* Right Graphics */}
                <div className='core-right-graphic'>
                    <div className='core-graphic-fixed'>
                        <div className='core-1-graphic'>
                            <img
                                className='core-graphic'
                                src={vendingGraphic}
                                alt='Vending Graphic'
                            />
                        </div>
                    </div>
                    <div
                        className={`core-graphic-scroll ${activeSection === 1 ? 'visible' : 'hidden'
                            }`}
                    >
                        <img
                            className='core-float-graphic'
                            src={core1Graphic}
                            alt='Core 1 Graphic'
                        />
                    </div>
                    <div
                        className={`core-graphic-scroll ${activeSection === 2 ? 'visible' : 'hidden'
                            }`}
                    >
                        <img
                            className='core-float-graphic'
                            src={core2Graphic}
                            alt='Core 2 Graphic'
                        />
                    </div>
                    <div
                        className={`core-graphic-scroll ${activeSection === 3 ? 'visible' : 'hidden'
                            }`}
                    >
                        <img
                            className='core-float-graphic'
                            src={core3Graphic}
                            alt='Core 3 Graphic'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CoreSection;
