import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './page/home';
import Header from './component/header';
import AboutUs from './page/about';
import News from './page/news';
import Solution from './page/solution';
import ContactUs from './page/contact';
import './style/theme.css';

/**
 * @author Nelson
 */
export class RouteUrl {
  static Default = '/';
  static Home = '/home';
  static All = '/*';
  static AboutUs = '/about/us';
  static News = '/news';
  static Solution = '/solution';
  static ContactUs = '/contact/us'
}

export const ROUTES = [
  {
    url: RouteUrl.Default,
    element: <Home />
  },
  {
    url: RouteUrl.Home,
    element: <Home />
  },
  {
    url: RouteUrl.All,
    element: <Home />
  },
  {
    url: RouteUrl.AboutUs,
    element: <AboutUs />
  },
  {
    url: RouteUrl.News,
    element: <News />
  },
  {
    url: RouteUrl.Solution,
    element: <Solution />
  },
  {
    url: RouteUrl.ContactUs,
    element: <ContactUs />
  }
]

/**
 * @author Nelson
 * @returns 
 */
function App() {
  return (
    <div style={{ width: '100%', minHeight: '100dvh', height: '100%', backgroundColor: '#00162f' }}>
      <Header />
      <Routes>
        {
          ROUTES.map((route, index) => {
            return <Route
              key={index}
              path={route.url}
              element={route.element} />
          })
        }
      </Routes>
    </div>
  );
}

export default App;