import React, { useState, useEffect, useRef } from 'react';
import vmallGraphic from '../../../asset/homepage/Vmall.png';
import core1Graphic from '../../../asset/homepage/Vmall_01.png';
import core2Graphic from '../../../asset/homepage/Vmall_02.png';
import core3Graphic from '../../../asset/homepage/Vmall_03.png';

/**
 * @author Angel
 * @returns 
 */

function CoreSection2() {
    const [activeSection, setActiveSection] = useState(1);

    const sectionsRef = useRef([]);

    const handleScroll = () => {
        sectionsRef.current.forEach((section, index) => {
            const rect = section.getBoundingClientRect();
            if (rect.top >= 0 && rect.top < window.innerHeight / 1.8) {
                setActiveSection(index + 1);
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className='core-2-wrapper page-wrapper'>
            <div className='padding-wrapper flex-wrapper'>
                {/* Left Content */}
                <div className='core-left-content'>
                    <div className='core-content-scroll'>
                        <label
                            className={`space-div black-title break-word white-space-normal ${activeSection === 1 ? 'focused' : ''
                                }`}
                        >
                            VMALL E-COMMERCE
                        </label>
                        <br />
                        <br />
                        <div
                            ref={(el) => (sectionsRef.current[0] = el)}
                            className={`core-1-content ${activeSection === 1 ? 'black-content-text' : 'gray-content-text'
                                }`}
                        >
                            Designed to <strong>extend the reach</strong> of vending machine operations into the <strong>digital
                                space</strong>, creating a seamless and integrated <strong>shopping experience</strong> for customers.
                        </div>
                        <br />
                        <div
                            ref={(el) => (sectionsRef.current[1] = el)}
                            className={`core-2-content ${activeSection === 2 ? 'black-content-text' : 'gray-content-text'
                                }`}
                        >
                            <strong>Offline to Online (O2O)</strong> integration allows businesses to bridge the gap
                            between physical and <strong>digital retail experience.</strong>
                        </div>
                        <br />
                        <div
                            ref={(el) => (sectionsRef.current[2] = el)}
                            className={`core-3-content ${activeSection === 3 ? 'black-content-text' : 'gray-content-text'
                                }`}
                        >
                            <strong>Key Features:</strong> Online storefront integration // omni-channel experience //
                            cross-promotion between offline and online // data synchronisation
                        </div>
                    </div>
                </div>

                {/* Right Graphics */}
                <div className='core-right-graphic'>
                    <div className='core-graphic-fixed'>
                        <div className='core-1-graphic'>
                            <img
                                className='core-graphic'
                                src={vmallGraphic}
                                alt='Vmall Graphic'
                            />
                        </div>
                    </div>
                    <div
                        className={`core-graphic-scroll2 ${activeSection === 1 ? 'visible' : 'hidden'
                            }`}
                    >
                        <img
                            className='core-float-graphic vmall-float-graphic'
                            src={core1Graphic}
                            alt='Core 1 Graphic'
                        />
                    </div>
                    <div
                        className={`core-graphic-scroll2 ${activeSection === 2 ? 'visible' : 'hidden'
                            }`}
                    >
                        <img
                            className='core-float-graphic vmall-float-graphic'
                            src={core2Graphic}
                            alt='Core 2 Graphic'
                        />
                    </div>
                    <div
                        className={`core-graphic-scroll2 ${activeSection === 3 ? 'visible' : 'hidden'
                            }`}
                    >
                        <img
                            className='core-float-graphic vmall-float-graphic'
                            src={core3Graphic}
                            alt='Core 3 Graphic'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CoreSection2;
