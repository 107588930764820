import React from 'react';

/**
 * @author Nelson
 * @returns 
 */
const OurService = () => {
    return (
        <div className='w-100 mt-8 our-service'>
            <div className='w-100 flex row justify-center align-item-center mb-2'>
                <button className='border-black no-select no-click' style={{ fontSize: '20px' }}>Our Services</button>
            </div>
            <div className='flex row relative justify-center align-item-center our-service-list'>
                <img className='our-service-item' src={require('../../../asset/homepage/our_services/VM_solution.png')} />
                <img className='our-service-item' src={require('../../../asset/homepage/our_services/software_dev.png')} />
                <img className='our-service-item' src={require('../../../asset/homepage/our_services/superapp_dev.png')} />
            </div>
            <div className='w-100 flex row justify-center align-item-center mb-2'>
                <img src={require('../../../asset/homepage/our_services/blend.png')} className='mt-8' style={{ maxWidth: '80%' }} />
            </div>
        </div>
    )
};

export default OurService;